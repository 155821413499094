import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import Header from "components/header"
import Director from "components/director"
import CTA from "components/call-to-action"

export default () => (
  <Layout>
    <Helmet>
      <title>Directors and management | Helios Underwriting</title>
    </Helmet>

    <main>
      <Header />

      {/*
        This article.o-content should wrap everything you want to fade in up
      */}
      <article className="o-content">
        <section className="padding-md">
          <h1 className="h1 mb-40">Directors and management</h1>
        </section>

        <Director
          name="Michael Wade"
          title="Executive Chairman"
          imageURL="/assets/board-portraits-circular-michael-wade.png"
        >
          <p>
            Michael has had an extensive career at Lloyd’s working across
            broking, underwriting, capital markets and insurance linked
            securities. Michael formed one of the first Lloyd’s corporate
            capital vehicles in 1993, CLM Insurance Fund plc, and later acted
            for UK pension funds via Rostrum Group investing in listed Lloyd’s
            firms. He has served on the Council and Committee of Lloyd’s and was
            formerly a senior adviser to the Cabinet Office. Currently, Michael
            is also a Senior Advisor to Mitsui Sumitomo Insurance. He was
            appointed Executive Chairman in June 2024.
          </p>
          <ul className="c-checklist">
            <li className="c-checklist__item">
              Nomination and remuneration committee
            </li>
          </ul>
        </Director>

        <Director
          name="Nigel Hanbury"
          title="Non-Executive Deputy Chairman"
          imageURL="/assets/board-portraits-circular-nigel.png"
        >
          <p>
            Nigel was temporarily appointed Non-Executive Deputy Chairman in
            June 2024, to ensure the smooth transition to a new CEO. Nigel was
            appointed Executive Deputy Chairman in April 2023 after stepping
            down as CEO, a position which he had filled since October 2012. He
            joined Lloyd’s in 1979 as an external member and became a Lloyd’s
            broker in 1982. He later moved to the members’ Agency side, latterly
            becoming Chief Executive and then Chairman of Hampden Agencies
            Limited. He serves on the board of the Association of Lloyd’s
            Members and was elected to the Council of Lloyd’s for the “Working
            Names” constituency, serving on that body between 1999 and 2001 and
            then 2005 to 2008, as well as participating on the Market Board and
            other Lloyd’s committees.
          </p>
          <p>
            In December 2009 he ceased being Chairman of Hampden. Nigel and/or
            his direct family underwrite at Lloyd’s through two LLVs.
          </p>
        </Director>

        <Director
          name="Arthur Manners"
          title="Finance Director"
          imageURL="/assets/board-portraits-circular-arthur.png"
        >
          <p>
            Arthur has over 20 years’ experience in the insurance industry. He
            has been a consultant to Helios since June 2015 and joined the Board
            in April 2016. His role as Finance Director at Helios is part time.
            He previously worked for Beazley Group plc from 1993 to 2009 as
            Finance Director and latterly as Company Secretary. He remains
            Chairman of the Trustees of the Beazley Furlonge Pension Scheme.
          </p>
        </Director>

        <Director
          name="Andrew Christie"
          title="Non-executive Director"
          imageURL="/assets/board-portraits-circular-andrew.png"
        >
          <p>
            Andrew Christie is a Senior Advisor of corporate finance advisory
            firm Smith Square Partners LLP and has nearly 30 years’ experience
            in corporate finance. Prior to Smith Square Partners, he was a
            managing director in the investment banking division of Credit
            Suisse Europe and prior to that he was head of investment banking in
            Asia Pacific for Credit Suisse First Boston and Barclays de Zoete
            Wedd.
          </p>
          <ul className="c-checklist">
            <li className="c-checklist__item">Chairman of Committee</li>
            <li className="c-checklist__item">Audit committee</li>
            <li className="c-checklist__item">
              Nomination and remuneration committee
            </li>
          </ul>
        </Director>

        <Director
          name="Tom Libassi"
          title="Non-executive Director"
          imageURL="/assets/board-portraits-circular-tom.png"
        >
          <p>
            Tom Libassi is the Co-Founder and Managing Partner of ILS Capital
            Management, an investment firm specialising in insurance-linked
            securities, of which funds under management of, or associated with,
            participated in the Company's recent fundraising. Prior to founding
            ILS Capital, Mr. Libassi was a Managing Director at Strategic Value
            Partners as well as holding the position of Chairman at Mach Gen
            LLC. From 2000 to 2007, Mr. Libassi was a Senior Managing Director
            at GSC Group where he founded the corporate lending group and served
            as a senior investment professional. From 1994 to 2000, Mr. Libassi
            was Senior Vice President and Portfolio Manager at Mitchell
            Hutchins, a subsidiary of PaineWebber Inc., where he was responsible
            for managing approximately $1.2 billion of high yield assets for the
            Paine Webber Mutual Funds. In 1998, Mr. Libassi developed and
            launched the approximately $550 million Managed High Yield Plus
            Fund, a leveraged closed-end fund. From 1986 to 1994, Mr. Libassi
            was a Vice President and Portfolio Manager at Keystone Custodian
            Funds, Inc., with portfolio management responsibilities for three
            diverse institutional high yield accounts with $250 million in
            collective assets. Mr. Libassi received an M.B.A. from the
            University of Pennsylvania and a B.A. in Economics and Government
            from Connecticut College.
          </p>
          <ul className="c-checklist">
            <li className="c-checklist__item">Audit committee</li>
            <li className="c-checklist__item">
              Nomination and remuneration committee
            </li>
          </ul>
        </Director>

        <Director
          name="Katharine Wade"
          title="Non-executive Director"
          imageURL="/assets/board-portraits-circular-katharine.png"
        >
          <p>
            Katharine brings over 29 years of experience in financial markets,
            at Board and management level roles within the UK and
            internationally. Katharine currently serves as Chief Financial
            Officer at Aegis, the top quartile Lloyd's syndicate, where she is
            responsible for strategy, capital raising and financial controls.
            Katharine has also held CFO roles at the UK entities within the
            Aspen Group (Aspen Insurance UK Limited and Aspen Managing Agency
            Limited) and ACE Tempest Re. Katharine's iNED experience includes
            being a non-executive director of Atlas Corporation (listed on NYSE)
            and membership of the Investment Committee of the Council of
            Lloyd's, where she monitors and oversees the Treasury and Investment
            department and the performance of the Lloyd's Investment Platform.
            She is also a fellow of the Institute of Chartered Accountants of
            England and Wales, Chair of the Worshipful Company of Insurers
            Charitable trust, and Court Assistant for the Worshipful Company of
            Insurers.
          </p>
          <ul className="c-checklist">
            <li className="c-checklist__item">Audit committee</li>
            <li className="c-checklist__item">
              Nomination and remuneration committee
            </li>
          </ul>
        </Director>

        <Director
          name="John Chambers"
          title="Non-executive Director"
          imageURL="/assets/board-portraits-circular-john.png"
        >
          <p>
            John previously worked at AEGIS London, where he was Director of
            Underwriting, and helped build the business from a fledgling
            syndicate into one of the largest and most profitable in the London
            Market. He brings considerable experience across all aspects of
            running a regulated insurer, from business planning through
            underwriting management to risk management, as well as financial and
            regulatory reporting. In addition to his role at Helios, John is a
            non-executive director of the CVC owned Lloyd’s syndicate Dale
            Underwriting Partners and a specialist non-executive director of the
            TT Club, the leading mutual insurer for the global logistics
            industry. He also advises AEGIS London and a number of institutional
            investors.
          </p>
          <ul className="c-checklist">
            <li className="c-checklist__item">Audit committee</li>
            <li className="c-checklist__item">
              Nomination and remuneration committee
            </li>
          </ul>
        </Director>

        <CTA
          title="Corporate Governance"
          link="/about-helios-underwriting/corporate-governance"
        />
      </article>
    </main>
  </Layout>
)
