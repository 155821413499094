import React from 'react'
import Divider from 'components/divider'

import styles from './director.module.scss';

export default props => (
  <div className={styles.block}>
    <div className={styles.image} style={{ backgroundImage: `url(${props.imageURL})` }}></div>
    <div>
      <h2 className={styles.name}>{props.name}</h2>
      <div className={styles.title}>{props.title}</div>
      <Divider color="steel"></Divider>
      <div className={styles.bio}>
        {props.children}
      </div>
    </div>
  </div>
)